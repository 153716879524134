import request from '@/utils/request.js';
/**
 * 门禁管理-获取设备列表
 * @returns {Promise}
 */
export function getDoorControlList(data) {
  return request({
    url: '/traffic-construction/doorControl/list',
    method: 'post',
    data
  });
}

/**
 * 门禁管理-添加设备
 * @returns {Promise}
 */
export function addDoorControl(data) {
  return request({
    url: '/traffic-construction/doorControl/add',
    method: 'post',
    data
  });
}

/**
 * 门禁管理-删除设备
 * @returns {Promise}
 */
export function deleteDoorControl(data) {
  return request({
    url: '/traffic-construction/doorControl/delete',
    method: 'post',
    data
  });
}

/**
 * 门禁管理-修改设备
 * @returns {Promise}
 */
export function editDoorControl(data) {
  return request({
    url: '/traffic-construction/doorControl/edit',
    method: 'post',
    data
  });
}
