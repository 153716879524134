export const validateRules = {
  data() {
    var validateOrgId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择所属机构'));
      }
      callback();
    };

    var validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入设备名称'));
      } else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };

    var validateType = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择设备类型'));
      }
      callback();
    };
    var validateDoorIp = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入设备IP'));
      }
      callback();
    };
    var validateDoorId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入设备ID'));
      }
      callback();
    };
    return {
      rules: {
        orgId: [{ validator: validateOrgId }],
        name: [{ validator: validateName }],
        type: [{ validator: validateType }],
        doorIp: [{ validator: validateDoorIp }],
        doorId: [{ validator: validateDoorId }]
      }
    };
  }
};
