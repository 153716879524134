<template>
  <div>
    <div class="search-list">
      <el-form inline>
        <el-form-item label="所属机构">
          <el-select v-model="queryForm.orgIdList" placeholder="请选择所属机构" multiple collapse-tags>
            <el-option v-for="item in orgList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备名称/设备IP">
          <el-input v-model="queryForm.searchValue" style="width:300px" placeholder="请输入设备名称/设备IP"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handelSearch">查询</el-button>
          <el-button @click="handelReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 操作按钮 -->
    <div class="operate-button">
      <el-button
        type="primary"
        icon="el-icon-plus"
        class="add-btn"
        @click="addAccessControl"
        v-if="$check('access-control_add')"
        >新增门禁</el-button
      >
    </div>
    <!-- 列表分页 -->
    <div>
      <el-table :data="tableData" stripe style="width: 100%" v-loading="tabelLoading" ref="multipleTabel">
        <el-table-column label="序号" width="55" fixed align="center">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="orgName" label="所属机构" fixed align="center" show-overflow-tooltip sortable>
        </el-table-column>
        <el-table-column prop="name" label="设备名称" align="center" show-overflow-tooltip sortable></el-table-column>
        <el-table-column prop="type" label="设备类型" align="center" show-overflow-tooltip sortable>
          <template slot-scope="{ row }">
            <span>{{ row.type === 1 ? '入口门禁' : '出口门禁' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="doorIp" label="设备IP" align="center" show-overflow-tooltip sortable> </el-table-column>
        <el-table-column prop="doorId" label="设备ID" align="center" show-overflow-tooltip sortable></el-table-column>
        <el-table-column prop="address" label="操作" fixed="right" align="center">
          <template slot-scope="{ row }">
            <span class="action-btn" @click="viewDetail(row)">查看详情</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :total="total"
        :current-page="queryForm.current"
        :page-size="queryForm.size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 50]"
        layout="total, sizes, prev, pager, next"
      ></el-pagination>
    </div>
    <!-- dialog 这个页面本身不多，没专门写组件-->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="doorVisible"
      width="590px"
      :close-on-click-modal="false"
      @close="resetForm"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
        v-loading="dialogLoading"
        class="form-box"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="所属机构" prop="orgId" :required="!isRead">
              <el-cascader
                v-model="ruleForm.orgId"
                :show-all-levels="false"
                :options="orgTreeList"
                :props="{ checkStrictly: true, emitPath: false, value: 'id', label: 'name' }"
                style="width:350px"
                clearable
                placeholder="请选择所属机构"
                :disabled="isRead"
              ></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="设备名称" :required="!isRead" prop="name">
              <el-input
                v-model="ruleForm.name"
                style="width:350px"
                placeholder="请输入设备名称"
                :disabled="isRead"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="设备类型" :required="!isRead" prop="type">
              <el-select v-model="ruleForm.type" placeholder="请选择设备类型" :disabled="isRead" style="width:350px">
                <el-option :value="1" label="入口门禁"> </el-option>
                <el-option :value="2" label="出口门禁"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="设备IP" :required="!isRead" prop="doorIp">
              <el-input
                v-model="ruleForm.doorIp"
                style="width:350px"
                placeholder="请输入设备IP"
                :disabled="isRead"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="设备ID" :required="!isRead" prop="doorId">
              <el-input
                v-model="ruleForm.doorId"
                style="width:350px"
                placeholder="请输入设备ID"
                :disabled="isRead"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" v-if="$check('access-control_edit')">
        <div v-if="!isRead">
          <el-button @click="resetForm">取消</el-button>
          <el-button @click="submitForm" type="primary" :loading="btnLoading">确定</el-button>
        </div>
        <div v-else>
          <el-button @click="deleteDoorFormData" type="danger">删除</el-button>
          <el-button @click="updateDoorFormData" type="primary">修改</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { validateRules } from './validateRules.js';
import {
  getDoorControlList,
  addDoorControl,
  deleteDoorControl,
  editDoorControl
} from 'api/general-user/access-control.js';
import { getDoorControlOrgList } from 'api/common';
import { getOrgTree } from 'api/general-user/health/dIsease/health';
export default {
  mixins: [validateRules],
  data() {
    return {
      queryForm: {
        searchValue: '',
        orgIdList: '',
        current: 1,
        size: 10
      },
      tabelLoading: false,
      projectList: [],
      tableData: [],
      total: 0,
      //dialog数据
      dialogTitle: '新增门禁',
      orgList: [],
      orgTreeList: [],
      doorVisible: false,
      ruleForm: {},
      isRead: false,
      dialogLoading: false,
      btnLoading: false
    };
  },
  methods: {
    resetForm() {
      this.doorVisible = false;
      this.isRead = false;
      this.$refs.ruleForm.resetFields();
    },
    submitForm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.ruleForm.id) {
            this.editDoorControl();
          } else {
            this.addDoorControl();
          }
        }
      });
    },
    //获取机构（列表-且有数据范围）
    getDoorControlOrgList() {
      getDoorControlOrgList().then(res => {
        if (res.code === 200) {
          this.orgList = res.data;
        }
      });
    },
    //获取机构（树-且有数据范围）
    getOrgTree() {
      getOrgTree().then(res => {
        if (res.code === 200) {
          this.orgTreeList = res.data;
        }
      });
    },
    addAccessControl() {
      this.dialogTitle = '新增门禁';
      this.ruleForm = {};
      this.doorVisible = true;
    },
    //查看详情
    viewDetail(row) {
      this.dialogLoading = true;
      this.dialogTitle = '查看详情';
      this.isRead = true;
      this.ruleForm = cloneDeep(row);
      this.doorVisible = true;
      setTimeout(() => {
        this.dialogLoading = false;
      }, 200);
    },
    handelSearch() {
      this.queryForm.current = 1;
      this.queryForm.size = 10;
      this.getDoorControlList();
    },
    handelReset() {
      //重置
      this.queryForm = {
        searchValue: '',
        orgId: '',
        current: 1,
        size: 10
      };
      this.$refs.multipleTabel.clearSort();
      this.getDoorControlList();
    },
    handleSizeChange(size) {
      this.queryForm.current = 1;
      this.queryForm.size = size;
      this.getDoorControlList();
    },
    handleCurrentChange(current) {
      this.queryForm.current = current;
      this.getDoorControlList();
    },
    deleteDoorFormData() {
      //删除
      this.$confirm('请确认是否删除该门禁', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.deleteDoorControl();
      });
    },
    //修改
    updateDoorFormData() {
      this.dialogTitle = '修改门禁';
      this.isRead = false;
      this.dialogLoading = true;
      setTimeout(() => {
        this.dialogLoading = false;
      }, 200);
    },
    //添加设备接口
    addDoorControl() {
      this.btnLoading = true;
      addDoorControl(this.ruleForm)
        .then(res => {
          if (res.code === 200) {
            this.btnLoading = false;
            this.$message.success('新增成功');
            this.doorVisible = false;
            this.getDoorControlList();
          }
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    //删除设备接口
    deleteDoorControl() {
      this.btnLoading = true;
      deleteDoorControl({ id: this.ruleForm.id }).then(res => {
        if (res.code === 200) {
          this.btnLoading = false;
          this.doorVisible = false;
          this.$message.success('删除成功');
          this.$refs.ruleForm.resetFields();
          this.getDoorControlList();
        }
      });
    },
    editDoorControl() {
      //修改接口
      this.btnLoading = true;
      editDoorControl(this.ruleForm)
        .then(res => {
          if (res.code === 200) {
            this.btnLoading = false;
            this.doorVisible = false;
            this.$message.success('修改成功');
            this.$refs.ruleForm.resetFields();
            this.getDoorControlList();
          }
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    getDoorControlList() {
      //列表分页
      this.tabelLoading = true;
      getDoorControlList(this.queryForm).then(res => {
        if (res.code === 200) {
          this.tabelLoading = false;
          const { records, total } = res.data;
          this.tableData = records;
          this.total = total;
        }
      });
    }
  },
  created() {
    this.getDoorControlList();
    this.getDoorControlOrgList();
    this.getOrgTree();
  }
};
</script>

<style scoped>
.operate-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
}
</style>
